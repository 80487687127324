.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
  filter: drop-shadow(0 0 0.50rem rgb(10, 10, 10));
}

.App-header {
  background: #22242E;
  background: radial-gradient(at center, #22242E, #0A0D18);

  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}